'use client';

import styles from './ActionButton.module.scss';

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  type?: 'button' | 'submit';
  disabled?: boolean;
  size?: 'xSmall' | 'small' | 'large' | 'fullWidth' | 'default';
  /** 参照: src/styles/partials/_variables.scss */
  color?: string;
  bgColor?: string;
  outlined?: boolean;
  isLoading?: boolean;
};

function ActionButton({
  children,
  onClick,
  type = 'button',
  disabled = false,
  size = 'default',
  color = 'black',
  bgColor = 'white',
  outlined = false,
  isLoading = false,
}: Props) {
  return (
    <button
      className={`${styles.actionButton} ${styles[size]}`}
      type={type}
      onClick={onClick}
      style={generateStyle({ color, bgColor, outlined })}
      disabled={isLoading || disabled}
    >
      {isLoading ? <div className={styles.loader}></div> : children}
    </button>
  );
}

const generateStyle = ({
  color,
  bgColor,
  outlined,
}: Pick<Props, 'color' | 'bgColor' | 'outlined'>): React.CSSProperties => {
  if (outlined) {
    return {
      color: `var(--color-${color})`,
      border: `2px solid var(--color-${color})`,
      background: `var(--color-${bgColor})`,
    };
  }

  // 色に応じてグラデーション
  switch (color) {
    case 'primary':
      return {
        border: 'none',
        background: 'linear-gradient(to top, #e11111, var(--color-primary))',
      };
    case 'blue-gradient':
      return {
        border: 'none',
        background: 'linear-gradient(to top, #1190e0, #33b0ff)',
      };
    default:
      return {
        border: `var(--color-${color})`,
        background: `var(--color-${color})`,
      };
  }
};

export default ActionButton;
