'use client';

import { ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import styles from './ModalBase.module.scss';

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
  children?: ReactNode;
  textColor?: string;
  bgColor?: string;
};

function ModalBase({ isOpen, onClose, children, textColor = 'black', bgColor = 'white' }: Props) {
  const generateStyle = ({ textColor, bgColor }: Pick<Props, 'textColor' | 'bgColor'>): React.CSSProperties => {
    if (bgColor === 'transparent') {
      return {
        backgroundColor: `transparent`,
      };
    }
    return {
      backgroundColor: `var(--color-${bgColor})`,
      color: `var(--color-${textColor})`,
    };
  };

  // warning対策
  const nodeRef = useRef(null);

  return (
    <CSSTransition in={isOpen} timeout={100} unmountOnExit classNames={styles} nodeRef={nodeRef}>
      <div className={styles.modalMask} ref={nodeRef}>
        <section className={styles.modalContainer}>
          <button type='button' className={styles.modalClose} onClick={() => onClose()} />
          <div className={styles.modalBody} style={generateStyle({ textColor, bgColor })}>
            {children}
          </div>
        </section>
      </div>
    </CSSTransition>
  );
}

export default ModalBase;
