import { useController } from 'react-hook-form';
import type { FieldValues, UseControllerProps } from 'react-hook-form/dist/types';

type Props<T extends FieldValues> = {
  autocomplete?: string;
  onChange: () => void;
  onPaste?: (e: React.ClipboardEvent<HTMLDivElement>) => void;
} & UseControllerProps<T>;

function SerialCodeFormText<T extends FieldValues>({ name, control, onChange, onPaste }: Props<T>) {
  const { field } = useController<T>({ name, control });

  return (
    <input
      type='text'
      autoComplete='off'
      name={field.name}
      ref={field.ref}
      onChange={(e) => {
        field.onChange(e);
        if (onChange) {
          onChange();
        }
      }}
      maxLength={16}
      onPaste={onPaste}
      value={field.value}
    />
  );
}
export default SerialCodeFormText;
